import React from "react";

import * as Utils from "../utils/utils";

const Title = ({ title }) => {
  return (
    <div className="section-title">
      <h1>{Utils.firstCharUpperCase(title) || "Veille sur ton coeur"}</h1>
      <div className="underline"></div>
    </div>
  );
};

export default Title;
