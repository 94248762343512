import * as React from "react";
import styled from "styled-components";
import { graphql, Link } from "gatsby";

import Layout from "../components/layout";
import Title from "../components/title";
import * as Utils from "../utils/utils";
import Feadback from "../components/forms/feedback";
import FeedbackContainer from "../components/container";
import Seo from "../components/seo";
import Subscribe from "../components/forms/subscribe";

const YOUTUBE_PLAYLIST_ITEMS_API =
  "https://www.googleapis.com/youtube/v3/playlistItems";
const YOUTUBE_EMBED_URL = "https://www.youtube.com/embed/";

const SinglePlaylistPage = ({ data, location }) => {
  const [playlistVideoData, setPlaylistVideoData] = React.useState();
  const [currentVideoIndex, setCurrentVideoIndex] = React.useState(0);

  const {
    title: playlistTitle,
    playlistId,
    desc: playlistDesc,
    remoteImage,
  } = data?.playlistYoutube;

  const seoImage = {
    width: 0.825,
    height: 1,
    src: remoteImage.publicURL,
  };

  React.useEffect(() => {
    let isCancelled = false;
    fetch(
      `${YOUTUBE_PLAYLIST_ITEMS_API}?part=snippet&maxResults=15&playlistId=${playlistId}&key=${process.env.GATSBY_YOUTUBE_API_KEY}`
    )
      .then((response) => response.json())
      .then((data) => {
        if (!isCancelled) {
          setPlaylistVideoData(data.items);
        }
      });
    return () => {
      isCancelled = true;
    };
  }, [playlistId]);

  const PlayListVideos = React.memo(() => {
    return (
      <section>
        {playlistVideoData.map(({ id, snippet = {} }, i) => {
          const { title, thumbnails = {} } = snippet;
          const { default: image } = thumbnails;
          return (
            <ItemLink
              key={i}
              to="#player"
              onClick={() => {
                setCurrentVideoIndex(i);
              }}
            >
              <ContainerYT
                style={
                  i === currentVideoIndex
                    ? { background: "var(--clr-primary-9)" }
                    : { background: "none" }
                }
              >
                <TitleYT>{i + 1}</TitleYT>
                <Thumbnail>
                  <img
                    // width={image.width}
                    // height={image.height}
                    src={image.url}
                    alt={title}
                  />
                </Thumbnail>
                <Metadata>
                  <TitleYT>
                    {Utils.firstCharUpperCase(title.toLowerCase())}
                  </TitleYT>
                </Metadata>
              </ContainerYT>
            </ItemLink>
          );
        })}
      </section>
    );
  });

  return (
    <Layout>
      <Seo
        title={playlistTitle}
        description={playlistDesc}
        image={seoImage}
        pathname={location.pathname}
      />
      <VideSection className="page">
        <Container id="player">
          <Title title={playlistTitle} id="#play" />

          {playlistVideoData && (
            <>
              <h3>
                Série de {playlistVideoData.length} vidéos -{" "}
                {currentVideoIndex + 1}/{playlistVideoData.length}
              </h3>
              <br />
              <ContentIframe>
                <iframe
                  src={`${YOUTUBE_EMBED_URL}${playlistVideoData[currentVideoIndex].snippet.resourceId.videoId}`}
                  title="video"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  frameBorder="0"
                  webkitallowfullscreen="true"
                  mozallowfullscreen="true"
                  allowFullScreen
                  width="560"
                  height="315"
                />
              </ContentIframe>

              <p
                style={{
                  border: "1px solid #e5eff5",
                  boxShadow: "0 2px 3px rgb(3 27 78 / 5%)",
                  borderRadius: "var(--radius)",
                  padding: "2rem 1.5rem",
                  marginBottom: "2rem",
                }}
              >
                {playlistDesc}
              </p>
              <PlayListVideos />
            </>
          )}
          <Link to="/ressources/" className="btn center-btn">
            Voir plus
          </Link>
        </Container>
      </VideSection>
      <FeedbackContainer>
        <Feadback
          article={playlistTitle}
          pathname={location.pathname}
          sectionTitle={"Avez-vous aimé cette vidéo ?"}
        />
      </FeedbackContainer>
      <Subscribe />
    </Layout>
  );
};

export const query = graphql`
  query($playlistId: String) {
    playlistYoutube: youTubePlaylistItems(playlistId: { eq: $playlistId }) {
      desc
      title
      remoteImage {
        relativePath
        publicURL
      }
      playlistId
    }
  }
`;

export default SinglePlaylistPage;

const VideSection = styled.section`
  width: 100%;
  padding: 5rem 0;
  // border-bottom: 1px solid #f1f1f1;
`;

const Container = styled.div`
  max-width: 1111px;
  width: 90%;
  margin: 0 auto;
`;

const ContentIframe = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  overflow: hidden;
  max-width: 100%;
  height: auto;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const ItemLink = styled(Link)`
  color: var(--clr-grey-3);
  :hover {
    color: var(--clr-primary-5);
    // text-decoration: underline;
  }
`;

const ContainerYT = styled.div`
  display: -webkit-flex;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;
  align-items: center;
  border: 1px solid rgb(229, 239, 245);
  box-shadow: rgb(3 27 78 / 5%) 0px 2px 3px;
  border-radius: var(--radius);
  margin-bottom: 0.5rem;
`;

const Thumbnail = styled.div`
  width: 100px;
  height: 75px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  display: block;
  -ms-flex: none;
  -webkit-flex: none;
  flex: none;
`;

const Metadata = styled.div`
  min-width: 0;
  padding: 0 8px;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
  -ms-flex: 1 1 1e-9px;
  -webkit-flex: 1;
  flex: 1;
  -webkit-flex-basis: 1e-9px;
  flex-basis: 1e-9px;
`;

const TitleYT = styled.h4`
  display: block;
  margin: 0 0 4px 0;
  display: -webkit-box;
  max-height: calc(2 * var(--yt-link-line-height, 1.6rem));
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  -webkit-line-clamp: 2;
  font-size: var(--yt-link-font-size, 1.2rem);
  font-weight: 500;
  line-height: var(--ytd-link-line-height, 1.6rem);
  letter-spacing: var(--yt-link-letter-spacing, normal);
`;
